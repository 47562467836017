import {switchMap, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FacilityChooserService} from './facility-chooser.service';
import {UserAuthService} from './user-auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {Human} from '../models/human';
import {Observable, ReplaySubject} from 'rxjs';
import {APIService} from './api.service';
import {ContentDefinitions} from '../definitions/definitions';
import {SlidePanelService} from '../portal/slide-panel/slide-panel.service';
import {Task} from '../models/models';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RecaptchaSolverComponent} from '../portal/@portal-theme/components/recaptcha-solver/recaptcha-solver.component';
import {UserSettingsService} from './user-settings.service';
import {CONSTANTS} from './constants';
import {CensusEvent} from '../models/census-event';
import {Patient} from '../models/patient';
import {DownloadService} from './download.service';
import {ToastService} from './toast.service';
import {Notification} from '../portal/user-status/notification/notification.component';
import {DownloadQueueStatsService} from '../portal/documents/download-queue/download-queue-stats.service';

@Injectable({
    providedIn: 'root',
})
export class ResultParserService {
    private _user: User;

    constructor(private fc: FacilityChooserService,
                private userAuth: UserAuthService,
                private router: Router,
                private slidePanelService: SlidePanelService,
                private modalService: NgbModal,
                private userSettingsService: UserSettingsService,
                private downloadService: DownloadService,
                private api: APIService,
                private toastService: ToastService,
                private downloadQueueStatsService: DownloadQueueStatsService,
                private activatedRoute: ActivatedRoute) {
        this.userAuth.user.subscribe(x => this._user = x);
    }

    private _types: any[] = [
        {
            aliases: ['facilities'],
            identifier: x => (x.organization ? x.organization.name : null),
            icon: () => ('building'),
            facility: x => null,
            action: x => (() => this.fc.setSelectedFacility(x.value)),
        },
        {
            aliases: ['pages'],
            identifier: x => (x.parent ? x.parent.name : null),
            action: x => (() => this.router.navigate([x.link], {queryParams: x.queryParams})),
        },
        {
            aliases: ['patients', 'patient'],
            name: x => (Human.getName(x)),
            icon: () => (ContentDefinitions.PATIENT.icon),
            identifier: x => SimpleDatePipe.transform(x.date_ob, false),
            color: x => CensusEvent.colorClass(CensusEvent.occupancyStatus(x.census_status)),
            status: x => x.census_status && CONSTANTS[x.census_status] || x.in_house && 'In House' || null,
            bstRecordId: x => Patient.getBstRecordId(x),
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.slidePanelService.addComponent('PATIENT', x.id)),
        },
        {
            aliases: ['ccm_note', 'ccm_note_tagged', 'fax_send_failed'],
            icon: () => ('pen-alt'),
            action: x => (() => this.slidePanelService.addComponent('TASK', x.target_object_id || x.id)),
            color: x => (x.notification_type === 'fax_send_failed' ? 'danger' : null),
            getObject: x => (() => {
                const obs$ = new ReplaySubject<Task>(1);
                Task.retrieve(x.target_object_id || x.id).subscribe(res => obs$.next(res), err => obs$.error(err));
                return obs$;
            }),
        },
        {
            aliases: ['lab_report', 'critical_lab_report'],
            icon: () => (ContentDefinitions.LAB_REPORT.icon),
            identifier: x => (x.id),
            action: x => (() => this.slidePanelService.addComponent('LAB_REPORT', x.target_object_id || x.id)),
        },
        {
            aliases: ['users', 'user'],
            name: x => (Human.getName(x)),
            icon: () => ('user'),
            identifier: x => (x.email),
            facility: x => (this._parseFacility(this._getPrimaryFacility(x.facilities))),
            action: x => (() => this._facilityCheck(this._getPrimaryFacility(x.facilities)).subscribe(() => this.router.navigate([`/admin/users/${x.id}`]))),
        },
        {
            aliases: ['init_census'],
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`admin/initial-import/${x.facility}`], {queryParams: {notification: 'init_census'}})),
        },
        {
            aliases: ['init_census_error', 'init_patient_import_error', 'init_patient_list_error', 'init_data_import_error'],
            color: () => 'danger',
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`admin/initial-import/${x.facility}`], {queryParams: {notification: x.notification_type}})),
        },
        {
            aliases: ['init_patient_list'],
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`admin/initial-import/${x.facility}`], {queryParams: {notification: 'init_patient_list'}})),
        },
        {
            aliases: ['init_patient_import'],
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`admin/initial-import/${x.facility}`], {queryParams: {notification: 'init_patient_import'}})),
        },
        {
            aliases: ['init_data_import'],
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`admin/initial-import/${x.facility}`], {queryParams: {notification: 'init_data_import'}})),
        },
        {
            aliases: ['census_check_completed'],
            facility: x => (this._parseFacility(x.facility)),
            action: x => (() => this.router.navigate([`system-maintenance/census-status-check`], {queryParams: {notification: 'census_check_completed', facility: x.facility}})),
        },
        {
            aliases: ['verify_captcha'],
            action: x => (() => {
                const modalRef = this.modalService.open(RecaptchaSolverComponent, {size: 'sm'});
                modalRef.componentInstance.recaptchaPuzzleId = x.target_object_id;
            }),
        },
        {
            aliases: ['person_merge_confirmed', 'person_merge_rejected'],
            action: x => (() => this.router.navigate([`admin/patient-identification/${x.patient}/person/${x.person}`])),
        },
        {
            aliases: ['report_generated'],
            action: x => (() => this.router.navigate([`documents/download-queue`], {queryParams: {notification: 'report_generated'}})),
            buttonText: () => 'Download',
            buttonAction: (x: Notification) => (() => this.api.ReportViewSet.retrieve(x.target_object_id).pipe(
                switchMap(res => this.downloadService.downloadFileByUrl(this.api.urls.FileView.get(res.file)).pipe(
                    take(1),
                    tap({
                        next: () => {
                            this.downloadQueueStatsService.triggerDownloadQueueStatsUpdate$.next();
                        },
                        error: err => {
                            this.toastService.error('An error occurred while trying to download file');
                        },
                    }),
                ))
            ).subscribe()),
        },
        {
            aliases: ['wound_parsed', 'wound_parse_failed'],
            action: x => (() => {
                this.router.navigate([`patients/${x.patient}`], {queryParams: {tab: 'documents'}});
            }),
            buttonText: () => this.activatedRoute.snapshot.queryParams.tab === 'documents' ? null : 'Navigate to Documents',
            buttonAction: x => x.notification_type === 'wound_parse_failed' ?
                null :
                (() => {
                    this.router.navigate([`patients/${x.patient}`], {queryParams: {tab: 'documents'}});
                }),
            color: x => x.notification_type === 'wound_parsed' ? 'success' : 'danger',
        },
    ];

    getProperty(x: any, prop?: string, type?: string) {
        const t = this._types.find(t => t.aliases.includes(type));
        return (t && t[prop]) ? t[prop](x) : x[prop] || null;
    }

    private _parseFacility(x) {
        if (!x) return null;
        if (typeof x === 'number') {
            const f = this._user.organizations.reduce((acc, curr) => acc.concat(curr.facilities), []).find(f => f.id == x);
            return {id: x, name: f ? f.name : undefined};
        }
        return x;
    }

    private _getPrimaryFacility(facArray) {
        if (!facArray || !facArray.length) return null;
        const f = this.fc.selected.facility;
        return f && facArray.includes(f) ? f : facArray[0];
    }

    private _facilityCheck(fac): Observable<void> {
        return Observable.create(obs => {
            this.fc.pipe(take(1)).subscribe(orgFac => {
                if (orgFac.facility && fac && orgFac.facility != fac) this.fc.setSelectedFacility(fac);
                obs.next();
                obs.complete();
            });
        });
    }
}
